import _ from "lodash";
import { useSelector } from "react-redux";
import { selectComponentPropertyByPath } from "src/features/builder/selectors";
import { ComponentItemType } from "../../types";
import {
  flexChildIncludedProps,
  flexContainerIncludedProps,
  getItemClosestProp,
  gridChildIncludedProps,
  gridContainerIncludedProps,
} from "../../utils";

export const JsonThemes = [
  "apathy",
  "apathy:inverted",
  "ashes",
  "bespin",
  "brewer",
  "bright:inverted",
  "bright",
  "chalk",
  "codeschool",
  "colors",
  "eighties",
  "embers",
  "flat",
  "google",
  "grayscale",
  "grayscale:inverted",
  "greenscreen",
  "harmonic",
  "hopscotch",
  "isotope",
  "marrakesh",
  "mocha",
  "monokai",
  "ocean",
  "paraiso",
  "pop",
  "railscasts",
  "rjv-default",
  "shapeshifter",
  "shapeshifter:inverted",
  "solarized",
  "summerfruit",
  "summerfruit:inverted",
  "threezerotwofour",
  "tomorrow",
  "tube",
  "twilight",
];

export const variantTypes = ["contained", "outlined"];
export const uploadTypes = ["Image", "Audio", "Video"];
export const interactionableComponents = [ComponentItemType.TextField, ComponentItemType.CustomAutoCompleteBX];

export const containersTypes = [
  { id: ComponentItemType.FlexContainer, title: "Flex Container" },
  { id: ComponentItemType.GridContainer, title: "Grid Container" },
  { id: ComponentItemType.CustomContainer, title: "Custom Container" },
];
export const textFieldTypes = ["Text", "Number", "Email", "Password", "URL", "UUID"];
export const pageTypes = ["numbers", "letters"];
export const pagesOrientationTypes = ["row", "column"];
export const initialAccordionState = {
  general: false,
  dataSource: false,
  widthConfig: false,
  size: false,
  display: false,
  validation: false,
  chip: false,
  hint: false,
  media: false,
  image: false,
  upload: false,
  button: false,
  typography: false,
  iconButton: false,
  PaginationBar: false,
  flexContainer: false,
  gridContainer: false,
  RepeatedSection: false,
  flexChildIncludedProps: false,
  gridChildIncludedProps: false,
  StripePaymentElements: false,
  repeatedItem: false,
  chart: false,
  styles: false,
  JsonViewer: false,
  MarkdownViewer: false,
  ColorPicker: false,
  actionConfig: false,
  CustomGoogleMap: false,
  GoogleMapAutocomplete: false,
  date: false,
  time: false,
  select: false,
  radio: false,
  checkbox: false,
  visibility: false,
  disable: false,
  repeated: false,
  checkboxGroup: false,
  radioGroup: false,
  interactionConfig: false,
  stepper: false,
  navigator: false,
  dateTime: false,
  loading: false,
  AutoComplete: false,
  qr: false,
  sxStyle: false,
  icon: false,
  spinner: false,
};

//Set of component properties that can change due to screen resize (Styling and rendering properties)
export const responsiveProperties = new Set([
  "widthPx",
  "heightPx",
  "heightPercentage",
  "widthPercentage",
  "isPercentageHeight",
  "isPercentageWidth",
  "isDynamicHeight",
  "isDynamicWidth",
  "fixedWidth",
  "fixedHeight",
  "heightType",
  "widthType",
  "visibilityCondition",
  "selectedVisibilityType",
  "disableCondition",
  "fontSize",
  ...flexContainerIncludedProps,
  ...gridContainerIncludedProps,
  ...gridChildIncludedProps,
  ...flexChildIncludedProps,
]);
// Check if the component should render based on the render conditions
export const shouldComponentRender = (renderConditionPaths: any[], componentId: any) => {
  const selectorFunc = useSelector;
  const conditions = Array.isArray(renderConditionPaths) ? renderConditionPaths : renderConditionPaths ? [renderConditionPaths] : [];

  // If no conditions are provided, default to render
  if (conditions.length === 0) return true;

  // Evaluate all conditions
  return conditions.reduce((acc, condition, index) => {
    const conditionValue = selectorFunc(state => selectComponentPropertyByPath(state, componentId, condition.path));

    // Determine if the condition is met
    const isConditionMet =
      condition.inverseCompareValue !== undefined
        ? conditionValue !== condition.inverseCompareValue // Check for not-equal case
        : condition.includes
        ? Array.isArray(conditionValue) && conditionValue.includes(condition.includes)
        : condition.compareValue !== undefined
        ? conditionValue === condition.compareValue
        : conditionValue === condition.expectedValue;

    // Combine conditions based on the operation
    if (index === 0) {
      // Initialize accumulator with the first condition result
      return isConditionMet;
    }

    if (condition.operation === "OR") {
      return acc || isConditionMet; // Any condition can make it true
    } else if (condition.operation === "AND") {
      return acc && isConditionMet; // All conditions must be true
    }

    // Default fallback (if no operation specified)
    return isConditionMet;
  }, true); // Start with true for AND logic
};

// Evaluate conditions and return the value of the first condition that matches
export const evaluateConditions = (state, componentId, conditions: any = [], fallBackValue) => {
  for (const condition of conditions) {
    const { path, expectedValue, returnValue } = condition;
    const actualValue = selectComponentPropertyByPath(state, componentId, path);
    if (actualValue === expectedValue) {
      return returnValue;
    }
  }

  return fallBackValue;
};

// Resolve the disabled state of the component based on the conditions
export const resolveDisabledState = (state: any, componentId: string, conditions: any): boolean => {
  return conditions?.some(({ disabledPath, disabledCondition }) => {
    const actualValue = selectComponentPropertyByPath(state, componentId, disabledPath);
    return actualValue === disabledCondition;
  });
};

export const resolveProPathStyle = (proPathStyle, values) => {
  const resolved = {};

  proPathStyle?.forEach(({ conditional, fallbackValue, property }, index) => {
    const value = values[index];

    // If the conditional function is provided, use it
    if (conditional && typeof conditional === "function") {
      resolved[property] = conditional(value);
    } else if (value !== undefined) {
      // Use the value directly if defined
      resolved[property] = value;
    } else {
      // Use fallback value if the property is not defined
      resolved[property] = fallbackValue;
    }
  });

  return resolved;
};

export const selectComponentValues = (proPathStyle, componentId, state) => {
  return proPathStyle?.map(({ path }) => selectComponentPropertyByPath(state, componentId, path));
};

export const getPropertyValue = (val, layoutBreak, isStyleObject = false) => {
  if (typeof val !== "object" || val === null || Array.isArray(val)) {
    return val;
  }

  if (isStyleObject) {
    return flattenResponsiveStyles(val, layoutBreak);
  }

  if (val && _.has(val, layoutBreak)) {
    return val[layoutBreak];
  }

  if (val?.lg) {
    return val?.lg;
  }

  return getItemClosestProp(val, layoutBreak);
};

export const storeResponsiveValue = (property, breakpoint, currentElement) => {
  if (breakpoint === "lg") {
    return `${property}.lg`;
  }

  const currentPropertyValue = _.get(currentElement, property);

  if (currentPropertyValue?.hasOwnProperty("lg")) {
    return `${property}.${breakpoint}`;
  }

  //Set the default/previous value as lg value
  _.set(currentElement, `${property}.lg`, currentPropertyValue);

  return `${property}.${breakpoint}`;
};

export const isResponsiveProperty = (propPath: string): boolean => {
  if (!propPath) {
    return false;
  }

  if (responsiveProperties.has(propPath)) {
    return true;
  }

  //Parse nested property paths to get last key
  if (propPath.includes(".")) {
    const keys = propPath.split(".");
    const targetProperty = keys[keys.length - 1];

    return responsiveProperties.has(targetProperty);
  }

  return false;
};

export const flattenResponsiveStyles = (responsiveStyles: any, currentBreakpoint: string) => {
  const flatStyles: Record<string, any> = {};

  Object.keys(responsiveStyles || {}).forEach(property => {
    const value = responsiveStyles[property];

    if (typeof value === "object" && value !== null) {
      // If the value is an object (responsive map), resolve based on the current breakpoint
      flatStyles[property] = value[currentBreakpoint] ?? value.lg ?? null;
    } else {
      // Directly assign non-object values
      flatStyles[property] = value;
    }
  });

  return flatStyles;
};
