import { isObject } from "hd-utils";
import _ from "lodash";
import { ChangeEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateComponent } from "src/features/builder/builderSlice";
import { selectComponentById, selectComponentPropertyByPath } from "src/features/builder/selectors";
import store, { RootState } from "src/store/store";
import { ComponentItemType } from "../../types";
import { flexContainerIncludedProps, gridContainerIncludedProps } from "../../utils";
import { customContainerConfig, flexContainerConfig, gridContainerConfig } from "../../viewBuilderComponentConfig";
import { isResponsiveProperty, storeResponsiveValue } from "./componentEditorUtils";

const useComponentConfig = (
  componentId,
  setIsModalOpen,
  setIsSuggestDataOpen,
  styles,
  stripeRulesState,
  stripeVariablesState,
  setIsInteractionConfigModalOpen,
  setStripeModalOpen,
  setStyles,
  setStripeVariablesState,
  setStripeRulesState
) => {
  const dispatch = useDispatch();
  let component = useSelector(state => selectComponentPropertyByPath(state, componentId, "props.component"));
  let isAdvance = useSelector(state => selectComponentPropertyByPath(state, componentId, "props.isAdvance"));
  let isMapValues = useSelector(state => selectComponentPropertyByPath(state, componentId, "props.isMapValues"));
  let currentBreakpoint = useSelector((state: RootState) => state.builder.layoutBreak);

  const handleChangeStyles = (newStyle: any) => {
    setStyles(newStyle);
  };

  const handleSaveStyles = (newStyle: any) => {
    setStripeVariablesState(newStyle);
  };

  const handleSaveRules = (newStyle: any) => {
    setStripeRulesState(newStyle);
  };

  const handleSave = data => {
    const item = selectComponentById(store.getState(), componentId);
    const updatedItem = { ...item, configData: { ...data } };
    dispatch(
      updateComponent({
        id: componentId,
        changes: updatedItem,
      })
    );
    setIsModalOpen(false);
  };

  const handleSaveSelectComponent = (data, selectedValue) => {
    const item = selectComponentById(store.getState(), componentId);
    const updatedItem = {
      ...item,
      name: data.name,
      configData: { ...data.config },
      dataSourceMap: {
        ...item?.dataSourceMap,
        [selectedValue]: { ...data.config },
      },
    };
    setIsModalOpen(false);
    dispatch(
      updateComponent({
        id: componentId,
        changes: updatedItem,
      })
    );
  };

  const handleSaveSuggestComponent = data => {
    const item = selectComponentById(store.getState(), componentId);
    const updatedItem = {
      ...item,
      name: data.name,
      config: {
        ...item.config,
        suggestionEndpoint: {
          ...data.config,
        },
      },
    };
    dispatch(
      updateComponent({
        id: componentId,
        changes: updatedItem,
      })
    );
    setIsSuggestDataOpen(false);
  };

  function getFileType(filename: string): string {
    const extension = filename.split(".").pop()?.toLowerCase() ?? "";
    if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif") {
      return "img";
    } else if (extension === "mp3" || extension === "wav" || extension === "ogg") {
      return "audio";
    } else if (extension === "mp4" || extension === "avi" || extension === "mov") {
      return "video";
    } else {
      return "auto";
    }
  }

  const saveStyleHandler = () => {
    const item = selectComponentById(store.getState(), componentId);
    const newStyles: any = { ...styles }; // Styles for the current breakpoint.
    const styleProps = _.cloneDeep(item?.props?.sx || {});

    Object.entries(newStyles).forEach(([property, newValue]) => {
      // Retrieve or initialize the style property for breakpoints
      const existingValues = _.get(styleProps, property, {});

      // Handle large breakpoint (`lg`) as the default or persistent value
      if (currentBreakpoint === "lg") {
        _.set(styleProps, `${property}.lg`, newValue);
      } else {
        // For non-`lg` breakpoints, persist the default (`lg`) value
        if (typeof existingValues === "string") {
          _.set(styleProps, `${property}.lg`, existingValues); // Set the existing value as `lg` if it's a string
        }
        // Set the new value for the current breakpoint
        _.set(styleProps, `${property}.${currentBreakpoint}`, newValue);
      }
    });

    const newItem = { ...item, props: { ...item?.props, sx: { ...styleProps } } };
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const saveStripeVariablesHandler = () => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = { ...item, props: { ...item?.props, stripeVariables: { ...(stripeVariablesState as any) } } };
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const saveStripeRulesHandler = () => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = { ...item, props: { ...item?.props, stripeRules: { ...(stripeRulesState as any) } } };
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };
  const handleConfigChange =
    (type: string, dataType?: string, overrideValue?: (val: any) => any) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | boolean) => {
      const item = selectComponentById(store.getState(), componentId);

      const newItem = JSON.parse(JSON.stringify(item));
      let val: any = typeof e === "object" ? e.target.value : e;

      if (["minutesStep", "secondsStep"].includes(type)) {
        val = Math.max(1, Math.min(60, Number(val)));
      } else if (type === "hoursStep") {
        val = Math.max(1, Math.min(24, Number(val)));
      }

      if (dataType === "number") {
        val = Number(val);
      } else if (dataType === "boolean") {
        val = !_.get(newItem, "config." + type);
      }

      val = overrideValue ? overrideValue(val) : val;

      if (isResponsiveProperty(type)) {
        type = storeResponsiveValue(type, currentBreakpoint, newItem.config);
      }

      const keys = type.split(".");
      let current = newItem.config;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = val;
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });

      if (type === "uploadConfig.uploadAllowedTypes" && (val.includes("Video") || val.includes("Audio"))) {
        newItem.config.uploadConfig = {
          ...newItem.config.uploadConfig,
          isCropImage: false,
        };
      }

      if (type === "uploadConfig.multipleFiles" && val) {
        newItem.config.uploadConfig.isCropImage = false;
      }

      if (type === "uploadConfig.isCropImage" && val) {
        newItem.config.uploadConfig.multipleFiles = false;
      }

      dispatch(
        updateComponent({
          id: componentId,
          changes: newItem,
        })
      );
    };

  const handleDateConfigChange = (type, newDate) => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = { ...item };
    _.set(newItem, "config." + type, newDate);
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const onInteractionConfigSave = newConfig => {
    const item = selectComponentById(store.getState(), componentId);
    const updatedItem = {
      ...item,
      interactionConfig: [...newConfig.interactionConfig],
    };

    dispatch(
      updateComponent({
        id: componentId,
        changes: updatedItem,
      })
    );
    setIsInteractionConfigModalOpen(false);
  };

  const onIntentConfigSave = newConfig => {
    const item = selectComponentById(store.getState(), componentId);
    const updatedItem = {
      ...item,
      props: {
        ...item.props,
        stripePublishableEndpoint: {
          ...item.props.stripePublishableEndpoint,
          ...newConfig.props.stripePublishableEndpoint,
        },
        stripeSecretEndpoint: {
          ...item.props.stripeSecretEndpoint,
          ...newConfig.props.stripeSecretEndpoint,
        },
      },
    };

    handleSetChangeProp?.(updatedItem);
    dispatch(
      updateComponent({
        id: componentId,
        changes: updatedItem,
      })
    );

    setStripeModalOpen(false);
  };

  const handleChangeProp =
    (type: string, options: { mapValues?: boolean; mapValuesObject?: any } = {}) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const item = selectComponentById(store.getState(), componentId);
      let newItem = _.cloneDeep(item);
      const val = (isObject(e) ? e.target?.value : e) || "";
      const newValue = options?.mapValues
        ? {
            mapValuesObject: options.mapValuesObject,
            value: val,
          }
        : val;
      if (isResponsiveProperty(type)) {
        type = storeResponsiveValue(type, currentBreakpoint, newItem.props);
      }
      if (flexContainerIncludedProps.includes(type) || gridContainerIncludedProps.includes(type)) {
        _.set(newItem.props, type, newValue);
      } else if (type === "src" && component === "auto") {
        newItem = { ...item, props: { ...item?.props, component: getFileType(val), [type]: newValue } };
      } else {
        _.set(newItem.props, type, newValue);
      }

      dispatch(
        updateComponent({
          id: componentId,
          changes: newItem,
        })
      );
    };

  const handleChangeGridTemplateColumns = (type: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = _.cloneDeep(item);
    const val = (isObject(e) ? e.target?.value : e) || "";
    _.set(newItem, `props.${type}`, val);

    if (
      type.includes("extraSmall") ||
      type.includes("small") ||
      type.includes("medium") ||
      type.includes("large") ||
      type.includes("extraLarge")
    ) {
      newItem.props.sx.gridTemplateColumns = {
        xs: `repeat(${newItem.props?.extraSmall}, 1fr)`,
        sm: `repeat(${newItem.props?.small}, 1fr)`,
        md: `repeat(${newItem.props?.medium}, 1fr)`,
        lg: `repeat(${newItem.props?.large}, 1fr)`,
        xl: `repeat(${newItem.props?.extraLarge}, 1fr)`,
      };
    }
    if (isAdvance) {
      _.set(newItem, `props.sx.${type}`, val);
    }
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleSetChangeProp = (type: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = _.cloneDeep(item);
    const val = (isObject(e) ? e.target?.value : e) || "";

    if (isResponsiveProperty(type)) {
      type = storeResponsiveValue(type, currentBreakpoint, newItem);
    }

    _.set(newItem, type, val);

    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleSetChangeIconProp = (path: string) => (newValue: any) => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = _.cloneDeep(item);
    if (newValue.icon) {
      _.set(newItem, `${path}.icon`, newValue.icon);
    }
    if (newValue.url) {
      _.set(newItem, `${path}.url`, newValue.url);
    }
    if (newValue.visibility) {
      _.set(newItem, `${path}.visibility`, newValue.visibility);
    }
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleSetChangePropStyle = (type: string) => (value: any) => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = _.cloneDeep(item);
    const val = value || "";

    if (isResponsiveProperty(type)) {
      type = storeResponsiveValue(type, currentBreakpoint, newItem);
    }

    const updatedItem = _.set(newItem, type, val);

    dispatch(
      updateComponent({
        id: componentId,
        changes: updatedItem,
      })
    );
  };

  const changeContainerType = (item: any, value: any) => {
    if (value === ComponentItemType.GridContainer) {
      return {
        ...item,
        type: gridContainerConfig.type,
        props: {
          ...item.props,
          sx: gridContainerConfig.props.sx,
        },
        config: {
          ...item.config,
        },
      };
    } else if (value === ComponentItemType.FlexContainer) {
      return {
        ...item,
        type: flexContainerConfig.type,
        props: {
          ...item.props,
          sx: flexContainerConfig.props.sx,
        },
        config: {
          ...item.config,
        },
      };
    } else {
      return {
        ...item,
        type: customContainerConfig.type,
        props: {
          ...item.props,
          sx: customContainerConfig.props.sx,
        },
        config: {
          ...item.config,
        },
      };
    }
  };

  const handleChangeSelect = (type: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const item = selectComponentById(store.getState(), componentId);
    let newItem = _.cloneDeep(item);
    const val = event.target.value;

    // newItem.props = {
    //   ...newItem.props,
    //   [type]: value,
    // };

    if (isResponsiveProperty(type)) {
      type = storeResponsiveValue(type, currentBreakpoint, newItem.props);
    }

    _.set(newItem, type, val);

    if (type === "containerType") {
      newItem = changeContainerType(newItem, val);
    }

    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleChangeButtonProps = (type: string) => (event: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any) => {
    const item = selectComponentById(store.getState(), componentId);
    const newItem = { ...item };
    _.set(newItem, "props." + type, event.target.value + "!important");
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const updateProps = props => {
    let newProps = { ...props };
    if (isMapValues) {
      for (let key in newProps) {
        if (newProps.hasOwnProperty(key)) {
          const prop = newProps[key];
          if (typeof prop === "object" && prop !== null && "mapValuesObject" in prop && key !== "sx") {
            newProps[key] = prop.value;
          }
        }
      }
    }
    return newProps;
  };

  const handleMapValuesToggle = (type: string, callback?: (item: any, value: boolean) => void) => () => {
    const item = selectComponentById(store.getState(), componentId);
    const { prevSelectedType, selectedType, optionMap, ...restOfItem } = item || {};
    let savePrevType = { ...item };
    if (item?.optionMap && isMapValues) {
      savePrevType = {
        ...item,
        optionMap: {
          ...item?.optionMap,
          [item?.selectedType]: { ...restOfItem },
        },
      };
      dispatch(
        updateComponent({
          id: componentId,
          changes: savePrevType,
        })
      );
    }
    if (item?.optionMap && !isMapValues) {
      const updateDefault = {
        ...item,
        optionMap: {
          ...item?.optionMap,
          default: { ...restOfItem },
        },
      };
      dispatch(
        updateComponent({
          id: componentId,
          changes: updateDefault,
        })
      );
    }

    let clonedOptionMap = _.cloneDeep(savePrevType?.optionMap);

    let newProps = updateProps(clonedOptionMap ? clonedOptionMap?.default?.props : item?.props);

    let itemChange = clonedOptionMap ? { ...clonedOptionMap?.default } : { ...item };
    if (item?.props?.[type] == false) {
      itemChange = { ...item };
      newProps = item?.props;
    }

    const newItem = {
      ...itemChange,
      props: { ...newProps, [type]: !item?.props?.[type] },
      optionMap: clonedOptionMap
        ? {
            ...clonedOptionMap,
            default: { ...itemChange, props: { ...newProps, [type]: !item?.props?.[type] } },
          }
        : undefined,
      selectedType: "default",
    };

    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleToggleProp = (type: string, callback?: (item: any, value: boolean) => void) => () => {
    const item = selectComponentById(store.getState(), componentId);
    let newItem = item;
    newItem = {
      ...newItem,
      props: { ...newItem?.props, [type]: !newItem?.props?.[type] },
    };
    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
    callback?.(newItem, !item?.props?.[type]);
  };

  const handleAdvanceToggleChange = (item, value) => {
    const newItem = { ...item, props: { ...item.props } };
    if (!value) {
      newItem.props.sx = {
        ...newItem.props.sx,
        gridTemplateColumns: {
          xs: `repeat(${item.props?.extraSmall}, 1fr)`,
          sm: `repeat(${item.props?.small}, 1fr)`,
          md: `repeat(${item.props?.medium}, 1fr)`,
          lg: `repeat(${item.props?.large}, 1fr)`,
          xl: `repeat(${item.props?.extraLarge}, 1fr)`,
        },
        gridTemplateRows: "none",
        gridTemplate: "none",
        gridTemplateAreas: "none",
        gridColumnGap: "normal",
        gridRowGap: "normal",
        justifyItems: "stretch",
        alignItems: "stretch",
        placeItems: "auto",
        justifyContent: "normal",
        placeContent: "normal",
        alignContent: "normal",
      };
    } else {
      newItem.props.sx.gridTemplateColumns = newItem?.props?.gridTemplateColumns;
      newItem.props.sx.justifyItems = newItem?.props?.justifyItems;
      newItem.props.sx.gridTemplateRows = newItem?.props?.gridTemplateRows;
      newItem.props.sx.gridTemplate = newItem?.props?.gridTemplate;
      newItem.props.sx.gridTemplateAreas = newItem?.props?.gridTemplateAreas;
      newItem.props.sx.gridRowGap = newItem?.props?.gridRowGap;
      newItem.props.sx.gridColumnGap = newItem?.props?.gridColumnGap;
      newItem.props.sx.alignItems = newItem?.props?.alignItems;
      newItem.props.sx.placeItems = newItem?.props?.placeItems;
      newItem.props.sx.justifyContent = newItem?.props?.justifyContent;
      newItem.props.sx.placeContent = newItem?.props?.placeContent;
      newItem.props.sx.alignContent = newItem?.props?.alignContent;
    }

    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleEditorChange = e => {
    const item = selectComponentById(store.getState(), componentId);
    const src = isObject(e) ? e : JSON.parse(e || "{}");
    dispatch(
      updateComponent({
        id: componentId,
        changes: { ...item, props: { ...item?.props, defaultValue: src } },
      })
    );
  };

  const handleWidthTypeChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    const item = selectComponentById(store.getState(), componentId);
    const newValue = event?.target?.value as string;
    const newItem = _.cloneDeep(item);

    const fixedWidthPath = storeResponsiveValue("fixedWidth", currentBreakpoint, newItem.config);
    const dynamicWidthPath = storeResponsiveValue("isDynamicWidth", currentBreakpoint, newItem.config);
    const widthTypePath = storeResponsiveValue("widthType", currentBreakpoint, newItem.props);

    _.set(newItem.config, fixedWidthPath, newValue === "fixed");
    _.set(newItem.config, dynamicWidthPath, newValue === "dynamic");
    _.set(newItem.props, widthTypePath, newValue);

    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  const handleHeightTypeChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    const item = selectComponentById(store.getState(), componentId);
    const newValue = event?.target?.value as string;
    const newItem = _.cloneDeep(item);

    const percentageHeightPath = storeResponsiveValue("isPercentageHeight", currentBreakpoint, newItem.config);
    const dynamicHeightPath = storeResponsiveValue("isDynamicHeight", currentBreakpoint, newItem.config);
    const heightTypePath = storeResponsiveValue("heightType", currentBreakpoint, newItem.props);

    _.set(newItem.config, percentageHeightPath, newValue !== "fixed");
    _.set(newItem.config, dynamicHeightPath, newValue === "dynamic");
    _.set(newItem.props, heightTypePath, newValue);

    dispatch(
      updateComponent({
        id: componentId,
        changes: newItem,
      })
    );
  };

  return {
    handleSave,
    handleSaveSelectComponent,
    handleSaveSuggestComponent,
    saveStyleHandler,
    saveStripeVariablesHandler,
    saveStripeRulesHandler,
    handleConfigChange,
    handleDateConfigChange,
    onInteractionConfigSave,
    onIntentConfigSave,
    handleChangeProp,
    handleChangeGridTemplateColumns,
    handleSetChangeProp,
    handleSetChangeIconProp,
    handleSetChangePropStyle,
    handleChangeSelect,
    handleChangeButtonProps,
    handleMapValuesToggle,
    handleToggleProp,
    handleAdvanceToggleChange,
    handleWidthTypeChange,
    handleHeightTypeChange,
    handleSaveRules,
    handleSaveStyles,
    handleChangeStyles,
    handleEditorChange,
  };
};

export default useComponentConfig;
